import React from 'react';
import Slider from 'react-slick'
import nmb from '../../assets/images/nmb.png'
import ava02 from '../../assets/images/printing.jpg'
import ava03 from '../../assets/images/mpesa.png'

import nbc from '../../assets/images/nbc.png'
import printing from '../../assets/images/printing.jpg'
import photocopy from '../../assets/images/photocopy.jpg'
import stationaries from '../../assets/images/stationaries.jpg'
import stationary from '../../assets/images/stationary.jpg'
import pastrol1 from '../../assets/images/meeting conference.jpg'
import mpesa from '../../assets/images/mpesa.png'
import pastrol2 from '../../assets/images/workshop.jpg'
import pastrol3 from '../../assets/images/pastrol 3.jpg'
import pastrol4 from '../../assets/images/retreate.jpg'
import airtelmoney from '../../assets/images/airtelmoney.png'


import '../../style/testimonial.css'

const Testimonial = () => {

    const settings = {
        dots:true,
        Infinite:true,
        autoplay:true,
        speed:3000,
        swipeToSlide:true,
        autoplaySpeed:2000,
        slidesToShow:3,
        
        responsive:[
            {
                breakpoint:992,
                settings:{
                    slidesToShow:2,
                    slidesToScroll:1,
                    Infinite:true,
                    dots:true,
                },
               },
                {
                    breakpoint:576,
                    settings:{
                        slidesToShow:1,
                        slidesToScroll:1,
                },
              },           
        ]
    }

  return(
    <>
  <div> 
  <h2 className='testimonial__title fs-12' >Stationary</h2>
  <Slider {...settings} className='w-70'> 

  <div className='testimonial py-4 px-3'>
   <div className='d-flex align-items-center gap-4 mt-3'>
      <img src={printing} className='w-50 h-25 rounded-2' alt='' />
      <div>
      <p>printing services</p>
   </div>
   </div>
 </div>

 <div className='testimonial py-4 px-3'>
 <div className='d-flex align-items-center gap-4 mt-3'>
    <img src={photocopy} className='w-50 h-25 rounded-2' alt='' />
    <div>
    <p>photocoping services</p>
 </div>
 </div>
</div>

<div className='testimonial py-4 px-3'>
<div className='d-flex align-items-center gap-4 mt-3'>
  <img src={stationary} className='w-50 h-25 rounded-2' alt='' />
  <div>
  <p>stationaries services</p>
</div>
</div>
</div>

<div className='testimonial py-4 px-3'>
<div className='d-flex align-items-center gap-4 mt-3'>
<img src={stationaries} className='w-50 h-25 rounded-2' alt='' />
<div>
<p>stationaries properties</p>
</div>
</div> 
</div>

</Slider>
</div>

<div className='other_services'> 
  <h2 className='testimonial__title  fs-11' >Pastrol and Religious Information</h2>
  <Slider {...settings} className='w-70'> 

  <div className='testimonial py-4 px-3'>
   <div className='d-flex align-items-center gap-4 mt-3'>
      <img src={pastrol1} className='w-50 h-25 rounded-2' alt='' />
      <div>
      <p>Meetings</p>
   </div>
   </div>
 </div>

 <div className='testimonial py-4 px-3'>
 <div className='d-flex align-items-center gap-4 mt-3'>
    <img src={pastrol2} className='w-50 h-25 rounded-2' alt='' />
    <div>
    <p>Workshops</p>
 </div>
 </div>
</div>

<div className='testimonial py-4 px-3'>
<div className='d-flex align-items-center gap-4 mt-3'>
  <img src={pastrol3} className='w-50 h-25 rounded-2' alt='' />
  <div>
  <p>spirtual serminar / seminor za kiloho</p>
</div>
</div>
</div>

<div className='testimonial py-4 px-3'>
<div className='d-flex align-items-center gap-4 mt-3'>
<img src={pastrol4} className='w-50 h-25 rounded-2' alt='' />
<div>
<p>retreat / mafungo</p>
</div>
</div> 
</div>

</Slider>
</div>


<div className='other_services'> 
  <h2 className='testimonial__title fs-12 mt-4 color-gold' >Financial Services / Wakala</h2>
  <Slider {...settings} className='w-70'> 

  <div className='testimonial py-4 px-3'>
   <div className='d-flex align-items-center gap-4 mt-3'>
      <img src={nmb} className='w-50 h-25 rounded-2' alt='' />
      <div>
      <p>50810066501</p>
      <p>Catholic Diocese of Singida</p>
   </div>
   </div>
 </div>

 
 <div className='testimonial py-4 px-3'>
 <div className='d-flex align-items-center gap-4 mt-3'>
 <img src={airtelmoney} className='w-50 h-25 rounded-2' alt='' />
 <div>
 <p>457659</p>
 <p>Catholic Diocese of Singida</p>
</div>
 </div>
 </div>
 
 <div className='testimonial py-4 px-3'>
 <div className='d-flex align-items-center gap-4 mt-3'>
    <img src={nbc} className='w-50 h-25 rounded-2' alt='' />
    <div>
    <p>031175001385</p>
    <p>Catholic Diocese of Singida</p>
 </div>
 </div>
</div>
<div className='testimonial py-4 px-3'>
<div className='d-flex align-items-center gap-4 mt-3'>
<img src={mpesa} className='w-50 h-25 rounded-2' alt='' />
<div>
<p>884737</p>
<p>Joachim.f.jumaini</p>
</div>
</div> 
</div>

</Slider>
</div>
  </>
)};

export default Testimonial