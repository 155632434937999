import React, { useState } from 'react';
import './booking.css';
import { Form, FormGroup, ListGroup, ListGroupItem, Button } from 'reactstrap';
import { useForm } from '@formspree/react';
import { useNavigate } from 'react-router-dom';

const Booking = ({ tour, avgRating }) => {
    const { price, reviews } = tour;
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({
        userId: '01', // later it will be dynamic
        userEmail: '',
        fullName: '',
        phone: '',
        guestSize: 1,
        bookAt: ''
    });

    const [state, handleSubmit] = useForm('xdoqbzqk');

    const handleChange = e => {
        setCredentials(prev => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const ServiceFee = 10;
    const totalAmount = Number(price) * Number(credentials.guestSize) + Number(ServiceFee);

    const handleClick = async e => {
        e.preventDefault();
        if (state.submitting) return;
        await handleSubmit(credentials);
        navigate('/thank-you');
    };

    return (
        <div className='booking'>
            <div className='booking__top d-flex align-items-center justify-content-between'>
                <h3>${price} <span>/Per Day</span></h3>
                <span className='tour__rating d-flex align-items-center '>
                    <i className='ri-star-fill'></i>
                    {avgRating === 0 ? null : avgRating}({reviews?.length})
                </span>
            </div>

            <div className='booking__form'>
                <h5>Information</h5>
                <Form className='booking__info-form' onSubmit={handleClick}>
                    <FormGroup>
                        <input type='text' placeholder='Full Name' id='fullName' required onChange={handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <input type='number' placeholder='Phone' id='phone' required onChange={handleChange} />
                    </FormGroup>
                    <FormGroup className='d-flex align-items-center gap-3'>
                        <input type='date' placeholder='' id='bookAt' required onChange={handleChange} />
                        <input type='number' placeholder='Guest' id='guestSize' required onChange={handleChange} />
                    </FormGroup>
                    <Button className='btn primary__btn w-100 mt-4' type='submit' disabled={state.submitting}>
                        Book Now
                    </Button>
                </Form>
            </div>

            <div className='booking__bottom'>
                <ListGroup>
                    <ListGroupItem className='border-0 px-0'>
                        <h5 className='d-flex align-items-center gap-1'>
                            ${price}<i className='ri-close-line'></i> 1 day
                        </h5>
                        <span>{price}</span>
                    </ListGroupItem>
                    <ListGroupItem className='border-0 px-0'>
                        <h5>Services Charge</h5>
                        <span>${ServiceFee}</span>
                    </ListGroupItem>
                    <ListGroupItem className='border-0 px-0 total'>
                        <h5>Total</h5>
                        <span>${totalAmount}</span>
                    </ListGroupItem>
                </ListGroup>
            </div>
        </div>
    );
};

export default Booking;
