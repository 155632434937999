import React from 'react';
import { Card, CardBody } from 'reactstrap';
import {Link} from 'react-router-dom';
import './tour-card.css';
import calculateAvgRating from '../utils/avgRatingd';
const FoodCard = ({tour}) => {

    const {food, foodimg, foodprice, reviews } = tour;

    const {totalRating, avgRating} = calculateAvgRating(reviews);
 
    return( 
        <div className='tour__card'>
       <Card>
           <div className='tour__img'>
               <img src={foodimg} alt='tour-img' />
           </div>



           <CardBody>
          <div className='card__top d-flex align-items-center
              justify-content-between'>
              <span className='tour__location d-flex align-items-center gap-1'>
               <i class='ri-map-pin-line'></i> {food}
              </span>
              <span className='tour__rating d-flex align-items-center gap-1'>
               <i class='ri-star-fill'></i> {avgRating  === 0 ? null : avgRating}
               {totalRating === 0 ? (
                'Not rated'
                 ) : (
                    <span>({reviews.length})</span>
                 )}             
              </span>
            </div>


          

          <div className='card__bottom d-flex align-items-center
           justify-content-between mt-3'>
            <h5>${foodprice} <span> /per plate</span></h5>

          </div>
       </CardBody> 
       </Card>      
    </div>  
  );
};

export default FoodCard