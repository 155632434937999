import aboutimg from '../assets/images/front building.jpg';
import { Container, Row, Col, Button} from 'reactstrap';



import '../components/FoodHeader/subheading.css'


import Subtitle from './../Shared/Subtitle';
import FeaturedFood from '../components/Featured-tours/FeaturedFood.jsx';
import Newsletter from '../Shared/Newsletter.jsx';

const Food = () => {
    
  return (

    <>
  <section>
   <Container>
      <Row>
        <Col lg='12 ' className='m-auto'>
        <div className='app__header app__wrapper section_padding'>
         <div className='app__wrapper_info'>
            <Subtitle subtitle={'Choose The New Flavor'}/>
            <img src={aboutimg} alt="spoon" className="spoon__img w-2 h-12"/>
             <h1 className='app__header-h1'>The Key to Fine Dinning</h1>
              <p className='p__opensans' >we offer best food with perfect customer service</p> 
              <button type='button' className='custom__buttton' id='food'>explore menu</button>

         </div>

         <div className='app__wrapper_img'>
         <img src={aboutimg} alt='header img'/>
         </div>

         
       </div>
        </Col>
      </Row>
   </Container>
  </section>

  
  
  <section>
     <Container>
       <Row>
         <Col lg='12' className='mb=5' id='food'>
            <Subtitle subtitle={'Explore'}/>
            <h2 className='featured__tour-title'>Our Food and Drinks</h2>
            </Col>
            <FeaturedFood/>
       </Row>
     </Container>
   </section>


   <section>
   <Container>
     <Row>
       <Col lg='12' className='mb=5'>
       </Col>
       <Newsletter/>
     </Row>
   </Container>
 </section>
  </>
)
  }
  
  export default Food
  