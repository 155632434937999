import React, { useState, useEffect } from 'react';
import CommonSection from '../Shared/CommonSection';
import '../style/tour.css';
import tourData from '../assets/data/tours';
import TourCard from './../Shared/TourCard';
import Newsletter from './../Shared/Newsletter';
import { Container, Row, Col } from 'reactstrap';

const Tours = () => {
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    // Replace the hardcoded value (5) with actual count from backend API
    const totalItems = tourData.length; // Example: Replace with actual API call for item count
    const pages = Math.ceil(totalItems / 4); // Assuming 4 items per page
    setTotalPages(pages);
  }, [currentPage]);

  return (
    <>
      <CommonSection title={'All Rooms and Hall'} />

      <section className='pt-8'>
        <Container>
          <Row>
            {tourData?.map((tour) => (
              <Col lg='3' md='6' sm='6' className='mb-4' key={tour.id}>
                <TourCard tour={tour} />
              </Col>
            ))}

            <Col lg='12'>
              <div className='pagination d-flex align-items-center justify-content-center mt-4 gap-3'>
                {[...Array(totalPages).keys()].map((number) => (
                  <span
                    key={number}
                    onClick={() => setCurrentPage(number)}
                    className={currentPage === number ? 'active__page' : ''}
                  >
                    {number + 1}
                  </span>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Newsletter />
    </>
  );
};

export default Tours;
