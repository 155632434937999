import React from 'react';
import ServiceCard from './ServiceCard';
import {Col} from "reactstrap";

import conference from '../assets/images/icons8-conference-hall-100.png'
import hotel from '../assets/images/icons8-3-star-hotel-100.png'
import catering from '../assets/images/icons8-catering-100.png'
import CustomizationImg from '../assets/images/guide.png'

const serviceData = [
    {
        imgUrl:conference,
        title: "CONFERENCE HALL",
        desc:"Discover the elegance of our adaptable conference halls that is perfect for events of any scale, designed with your needs in mind.",
    },

    {
        imgUrl:hotel,
        title: "HOTEL",
        desc:"Explore the comfort and convenience of our accommodations that is tailored to meet your needs and provide a relaxing stay.",
    },

    {
        imgUrl:CustomizationImg,
        title: "LOUNGE",
        desc:"Experience the inviting atmosphere of our lounge area that is designed to foster relaxation and social connections in a welcoming environment.",
    },

    {
        imgUrl:catering,
        title: "CATERING",
        desc:"Indulge in the culinary delights of our catering services that is offering a diverse menu to satisfy every palate and elevate your event experience.",
    },
]

const ServiceList = () => {
  return (  
  <> 
    {serviceData.map((item,index)=> (
        <Col lg="3" md="6" sm="12" className='mb-4' key={index}>
        <ServiceCard item={item} />
    </Col>
    ))}  
  </>
  );
};

export default ServiceList