

import gallerymembers from '../../assets/images/members.jpg'
import galleryhotelview from '../../assets/images/hotel view.jpg'
import galleryceremonyall from '../../assets/images/ceremony all.jpg'
import galleryfrontbuilding from '../../assets/images/front building.jpg'
import gallerydoublebedroom from '../../assets/images/double bedroom.jpg'
import galleryceremonyhall from '../../assets/images/ceremony hall.jpg'
import galleryzommember from '../../assets/images/zom member.jpg'
import security from '../../assets/images/security.jpg'
import sisven from '../../assets/images/sis ven.jpg'
import wedding from '../../assets/images/wdn.jpg'
import hall from '../../assets/images/hall.jpg'
import ceremonyhall from '../../assets/images/ceremony hall.jpg'



const galleryImages = [
      gallerymembers,
      galleryhotelview ,
      galleryceremonyall ,
      galleryfrontbuilding,
      gallerydoublebedroom,
      galleryceremonyhall ,
      galleryzommember,
      ceremonyhall,
      sisven,
      wedding,
      hall,
      security
]

export default galleryImages;