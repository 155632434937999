
import galleryfrontbuilding from '../images/omega.jpg'
import gallerydoublebedroom from '../images/paking hall.jpg'
import galleryceremonyhall from '../images/build.jpg'
import galleryzommember from '../images/show off.jpg'
import food1 from '../images/chemusho.png'
import food2 from '../images/chips kuku.jpg'
import food3 from '../images/wali nyama.jpg'
import food4 from '../images/biliani.jpg'



const tours = [
  {
    id: "01",
    title: "alpha",
    city: "3 (self room contain)",
    food: "sotojo",
    foodprice: 3500,
    address:'somewhere',
    price: "tsh/=",
    maxGroupSize: 10,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
      {
        name: "jhon doe",
        rating: 5,
      },
    ],
    avgRating: 4.5,
    photo: galleryzommember,
    foodimg: food1,
    featured: true,
  },
  {
    id: "02",
    title: "Johnkelly",
    city: "12 (self room contain)",
    food: "chips kuku",
    foodprice: 5000,
    address:'somewhere',
    price: "tsh/=",
    maxGroupSize: 8,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: galleryceremonyhall,
    foodimg: food2,
    featured: true,
  },
  {
    id: "03",
    title: "Umoja na Mapendo",
    city: "20 (self room contain)",
    food: "wali nyama",
    foodprice: 3000,
    address:'somewhere',
    price: "tsh/=",
    maxGroupSize: 8,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: gallerydoublebedroom ,
    foodimg: food3,
    featured: true,
  },
  {
    id: "04",
    title: "Omega ",
    city: "3(self room contain)",
    food: "biliani nyama",
    foodprice: 7000,  
    address:'somewhere',
    price: "tsh/=",
    maxGroupSize: 8,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: galleryfrontbuilding,
    foodimg: food4,
    featured: true,
  },
  
];

export default tours;
