import React from 'react';
import './footer.css'

import { Container, Row, Col, ListGroupItem, ListGroup } from 'reactstrap';

import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

const quick__links=[
  {
    path:'/home',
    display: 'Home'
  },

  {
    path:'about',
    display: 'About'
  },

  {
    path:'/tours',
    display: 'Rooms & Hall'
  },
];

const quick__links2 = [
  {
    path:'/food',
    display: 'food & drink'
  },

  {
    path:'login',
    display: 'Login'
  },

  {
    path:'/register',
    display: 'Register'
  },
];

const Footer = () => {

  const year = new Date().getFullYear();

  return( 
  <footer className='footer'>
     <Container>
       <Row>
          <Col lg='3'>
            <div className='logo'>
               <img src={logo} alt='' />
               <p>Social Training center Your Destination for Spiritual Growth and Community Engagement
               Explore our facilities:</p>

               <div className='social__links d-flex align-items-center gap-4'>
                 <span>
                   <Link to=''><i className='ri-youtube-line'></i></Link>
                 </span>
                 <span>
                   <Link to=''><i className='ri-github-fill'></i></Link>
                 </span>
                 <span>
                    <Link to=''><i className='ri-facebook-circle-line'></i></Link>
                 </span> 
                  <span>
                    <Link to=''><i className='ri-instagram-line'></i></Link>
                  </span>
               </div>
            </div>
          </Col>

          <Col lg='3'>
             <h5 className='footer__link-title'>Discover</h5>

             <ListGroup className='footer__quick-links'>
                {
                  quick__links.map((item,index)=>(
                     <ListGroupItem key={index} className='ps-0 border-0'>
                       <Link to={item.path}>{item.display}</Link>
                     </ListGroupItem>
                  ))
                }
             </ListGroup>
          </Col>
          <Col lg='3'>
          <h5 className='footer__link-tilte'>quick__links</h5>

          <ListGroup className='footer__quick-links'>
             {
               quick__links2.map((item,index)=>(
                  <ListGroupItem key={index} className='ps-0 b
                  order-0'>
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
               ))
             }
          </ListGroup>
          </Col>
          <Col lg='3'>
          <h5 className='footer__link-tilte'>Contact</h5>

          <ListGroup className='footer__quick-links'>                         
                  <ListGroupItem className='ps-0 border-0 d-flex
                   align-items-center gap-3'>                   
                   <h6 className='mb-0 d-flex align-items-center gap-2'>
                      <span><i class='ri-map-pin-line'></i></span>
                      Address:                      
                   </h6>

                   <p className='mb-0'>136, Singida</p>
                  </ListGroupItem> 
                  <ListGroupItem className='ps-0 border-0 d-flex
                   align-items-center gap-3'>                   
                   <h6 className='mb-0 d-flex align-items-center gap-2'>
                      <span><i class='ri-mail-line'></i></span>
                      Email:                      
                   </h6>
                   <p className='mb-0'>mgeamagdalena@gmail.com</p>

                  </ListGroupItem>

                  <ListGroupItem className='ps-0 border-0 d-flex
                   align-items-center gap-3'>                   
                   <h6 className='mb-0 d-flex align-items-center gap-2'>
                      <span><i class='ri-mail-line'></i></span>
                      Email:                      
                   </h6>
                   <p className='mb-0'>stcdiosingida@yahoo.com</p>

                  </ListGroupItem>

                  <ListGroupItem className='ps-0 border-0 d-flex
                   align-items-center gap-3'>                   
                   <h6 className='mb-0 d-flex align-items-center gap-2'>
                      <span><i class='ri-phone-fill'></i></span>
                        Phone:                      
                   </h6>

                   <a href="tel:+255766781603" className="mb-0">+255 766781603</a>
                  </ListGroupItem>

                  <ListGroupItem className='ps-0 border-0 d-flex
                   align-items-center gap-3'>                   
                   <h6 className='mb-0 d-flex align-items-center gap-2'>
                      <span><i class='ri-phone-fill'></i></span>
                        Phone:                      
                   </h6>
                   <a href="tel:+255 753388949" className="mb-0">+255 753388949</a>

                  </ListGroupItem>
          </ListGroup>
          </Col>

          <Col lg='12' className='text-center pt-5'>
          <p className='copyright'>copyright {year}, Developed by Misape. Designed by Msai All rights reserved</p>

          <p className='copyright color-red'>Contact: <a href="tel:+255766781603" className="mb-0">+255 693671032</a>
          
          </p>
          </Col>
       </Row>
     </Container>
  </footer>
)}
 
export default Footer