import React from 'react'
import tourData from '../../assets/data/tours'
import { Col } from 'reactstrap'
import FoodCard from '../../Shared/Foodcard';


const FeaturedFood = () => {
  return (
   <>    
     {tourData?.map(tour=>(
        <Col lg='3' md='6' sm='6' className='mb-4' key={tour.id}>
        <FoodCard tour={tour} />
        </Col>
      ))} 
    </>
   );
  };
  
export default FeaturedFood