import React from 'react'
import './newsletter.css'

import { Container, Row, Col } from 'reactstrap'
import social from '../assets/images/social.jpg'

const Newsletter = () => {
  return <section className='newsletter'>
    <Container>
       <Row>
           <Col lg='6'>
               <div className='newsletter__content'>
                  <h2>Stay updated with the latest news and events at our site by subscribing to our email.</h2>
                <form className='newsletter__input' netlify>
                   <input type='email' placeholder='Enter your email' />
                   <button className='btn newsletter__btn'>Subscribe</button>
                </form> 
                
                <p>Stay updated with the latest news and events at our site by subscribing to our email. list for exclusive updates and special offers..           
                </p>
               </div>      
           </Col>

           <Col lg='6'>
           <div className='newsletter__img'>
               <img src= {social} alt=''/>
           </div>
           </Col>
       </Row>
    </Container>
  </section>
}

export default Newsletter