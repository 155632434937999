import React, { useRef, useEffect, useState } from 'react';
import { Container, Row, Button } from 'reactstrap';
import { NavLink, Link } from 'react-router-dom';

import logo from '../../assets/images/logo.png';
import './header.css';

const nav__links = [
    {
        path: '/home',
        display: 'Home'
    },
    {
        path: '/about',
        display: 'About'
    },
    {
        path: '/tours',
        display: 'Rooms & Hall'
    },
    {
        path: '/food',
        display: 'food & drink'
    },
];

const Header = () => {
    const headerRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const stickyHeaderFunc = () => {
        window.addEventListener('scroll', () => {
            if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
                headerRef.current.classList.add('sticky__header');
            } else {
                headerRef.current.classList.remove('sticky__header');
            }
        });
    };

    useEffect(() => {
        stickyHeaderFunc();

        return () => window.removeEventListener('scroll', stickyHeaderFunc);
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className='header' ref={headerRef}>
            <Container>
                <Row>
                    <div className='nav__wrapper d-flex align-items-center justify-content-between'>
                        {/* ============ logo ============ */}
                        <div className='logo'>
                            <img src={logo} alt='' />
                        </div>
                        {/* ============ logo end========= */}
                        {/* ============ menu start========= */}
                        <div className={`navigation ${menuOpen ? 'show__menu' : ''}`}>
                            <ul className='menu mobile__menu__gap d-flex align-items-center'>
                                {nav__links.map((item, index) => (
                                    <li className='nav__item' key={index}>
                                        <NavLink
                                            to={item.path}
                                            className={navClass =>
                                                navClass.isActive ? 'active__link' : ''
                                            }
                                        >
                                            {item.display}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {/* ============ menu end========= */}
                        <div className='nav__right d-flex align-items-center gap-4'>
                            
                            <span className='mobile__menu' onClick={toggleMenu}>
                                {menuOpen ? (
                                    <i className='ri-close-line close__menu__btn'></i>
                                ) : (
                                    <i className='ri-menu-line open__menu__btn'></i>
                                )}
                            </span>
                        </div>
                    </div>
                </Row>
            </Container>
        </header>
    );
};

export default Header;
