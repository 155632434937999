import aboutimg from '../assets/images/background.jpg'
import sisterimg from '../assets/images/sister m.jpg'
import fartherpatric from '../assets/images/mwasibu patric.jpg'
import founder from '../assets/images/founder.jpg'
import tumainiimg from '../assets/images/tumaini.jpg'

import bishop from '../assets/images/bishop.jpeg'
// import aboutimg from '../assets/images/front building.jpg'


import { Container, Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';
import '../style/about.css'

import Testimonial from '../components/Testimonial/Testimonial';
import ServiceList from '../services/ServiceList';
import Subtitle from './../Shared/Subtitle';
import Newsletter from '../Shared/Newsletter';

const About = () => {
    
  return (

    <>
  <section>
   <Container>
      <Row>
         <Col lg='8' className='m-auto'>
         <div className='about__achievements'>
         <div className='container about__achievements-container'>
         <div className='about__achievements-left'>
         <img src= {aboutimg} alt=''/>
         </div>
         
         
         <div className="about__achievements-right">
         <div>
         <h1 className='about__achievements-right__title'>Our Vision</h1>
         <p>
             To be a source of inspiration, education and enpowerment for individual seeking to deepen their relation with God, live out with their faith
             more fully and make a positive impact in the World around them.
              </p>
              </div>


              <div>
              <h1 className='about__achievements-right__title'>Our Mission</h1>
              <p>
              We are commited to equip individuals with tools needed to act ethically, compassionately and effectively to promote social justice and community enpowerment.
                 </p>
                 </div>

                 <div>
                 <h1 className='about__achievements-right__title'>Our Goal</h1>
                 <p>
                 Our goal at Social Training center, is to be a catalyst for positive change in the lives of our community members. 
                 Through our programs and services, we aim to inspire personal growth, foster deep connections, and promote social impact. 
                    </p>
                    </div>
             </div>

             
         </div>
       </div>
         </Col>
      </Row>
   </Container>
  </section>

  
  <section>
  <Container>
     <Row>
       <Col lg='3'>
           <h5 className='services__subtitle'>What we serve</h5>
           <h2 className='services__title'>we  offer our best services</h2>
       </Col>
       <ServiceList/>
     </Row>
  </Container>
</section>

  

{/*================= Testimonial section start ===================*/}
<section>
<Container>
<Row>
   <Col lg='12'>
   <Subtitle subtitle={'Other Services'} />

   </Col>
   <Col lg='12 '>
   <Testimonial />
   </Col>
   </Row>
   </Container>
   </section>
   {/*================= Testimonial section end ===================*/}
   <section>
   <Container>
      <Row>
        <Col lg='14'>
        <Subtitle  subtitle={'Meet Our Team'} />
        <div class="container team__container">
        
        
        
        
        
        <article class="team__member">
        <div class="team__member__image">
        <img className='about__img' src={bishop} alt='' />
        </div>
        
        <div class="team__member-info">
        <h4>REV.Bishop Edward E. Mapunda</h4>
        <p>Bishop</p>
        </div>
        <div class="team__member-socials">
        <span>
        <Link to=''><i className='ri-youtube-line'></i></Link>
        </span>
        <span>
        <Link to=''><i className='ri-github-fill'></i></Link>
        </span>
        <span>
        <Link to=''><i className='ri-facebook-circle-line'></i></Link>
        </span> 
        <span>
        <Link to=''><i className='ri-instagram-line'></i></Link>
        </span>    
        </div>
        </article>

        <article class="team__member">
        <div class="team__member__image">
        <img className='about__img' src={fartherpatric} alt='' />
        </div>
        
        <div class="team__member-info">
        <h4>FATHER.Patric</h4>
        <p>FATHER</p>
        </div>
        <div class="team__member-socials">
        <span>
        <Link to=''><i className='ri-youtube-line'></i></Link>
        </span>
        <span>
        <Link to=''><i className='ri-github-fill'></i></Link>
        </span>
        <span>
        <Link to=''><i className='ri-facebook-circle-line'></i></Link>
        </span> 
        <span>
        <Link to=''><i className='ri-instagram-line'></i></Link>
        </span>    
        </div>
        </article>

        <article class="team__member">
        <div class="team__member__image">
        <img className='about__img' src={founder} alt='' />
        </div>
        
        <div class="team__member-info">
        <h4>SISTER.Scolar</h4>
        <p>FOUNDER</p>
        </div>
        <div class="team__member-socials">
        <span>
        <Link to=''><i className='ri-youtube-line'></i></Link>
        </span>
        <span>
        <Link to=''><i className='ri-github-fill'></i></Link>
        </span>
        <span>
        <Link to=''><i className='ri-facebook-circle-line'></i></Link>
        </span> 
        <span>
        <Link to=''><i className='ri-instagram-line'></i></Link>
        </span>    
        </div>
        </article>

        <article class="team__member">
        <div class="team__member__image">
        <img className='about__img' src={tumainiimg} alt='' />
        </div>
        
        <div class="team__member-info">
        <h4>FATHER: Tumaini</h4>
        <p>FATHER</p>
        </div>
        <div class="team__member-socials">
        <span>
        <Link to=''><i className='ri-youtube-line'></i></Link>
        </span>
        <span>
        <Link to=''><i className='ri-github-fill'></i></Link>
        </span>
        <span>
        <Link to=''><i className='ri-facebook-circle-line'></i></Link>
        </span> 
        <span>
        <Link to=''><i className='ri-instagram-line'></i></Link>
        </span>    
        </div>
        </article>
        
        <article class="team__member">
            <div class="team__member__image">
                <img src={sisterimg} alt='' />
            </div>

            <div class="team__member-info">
                <h4>SR.Magdalena M. Mgea</h4>
                <p>Director PSTC</p>
            </div>
            <div class="team__member-socials">
            <span>
            <Link to=''><i className='ri-youtube-line'></i></Link>
          </span>
          <span>
            <Link to=''><i className='ri-github-fill'></i></Link>
          </span>
          <span>
             <Link to=''><i className='ri-facebook-circle-line'></i></Link>
          </span> 
           <span>
             <Link to=''><i className='ri-instagram-line'></i></Link>
           </span>
            </div>
        </article>
      
        </div>
        </Col>
      </Row>
   </Container>
</section>
   
<Newsletter/>
  
  </>
)
  }
  
  export default About
  