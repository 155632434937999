import React from 'react';
import '../style/home.css';

import { Container, Row, Col} from 'reactstrap';
import heroVideo from '../assets/images/hero__video.mp4'
import frontbuilding from '../assets/images/front building (2).jpg'
import ceremonyhall from '../assets/images/advert.jpg'
import Subtitle from './../Shared/Subtitle';

import ServiceList from '../services/ServiceList';
import FeaturedTourList from '../components/Featured-tours/FeaturedTourList';
import MasonryImaesGallery from '../components/image-gallery/MasonryImaesGallery';
import Testimonial from '../components/Testimonial/Testimonial';
import Newsletter from '../Shared/Newsletter';
 

const Home = () => {
  return <>
  {/*=========== hero section ===========*/}
<section>
  <Container>
   <Row>
     <Col lg='6'>
         <div className='hero__content'>
               <div className='hero__subtitle d-flex align-items-center'>
                  <Subtitle subtitle={'Pastrol and Social Training Center'}/>
               </div>
               <h1>Welcome Social Training center always we Deserve to Provide Better and Quality Services<span
                className='highlight'> memmories</span></h1>
                <p>
                Explore the heart of Social Training center, where community, learning, and relaxation come together effortlessly.
                From the lively social area to the modern conference halls, every space is crafted to encourage growth and togetherness. 
                Enjoy comfort in our accommodations and savor delicious meals with our tailored catering services,
                all in an environment devoted to personal and professional development.           
                </p>
          </div>
      </Col>

      <Col lg='2'>
         <div className='hero__img-box'>
            <img src={ceremonyhall} alt=''/>
         </div>
      </Col>

      <Col lg='2'>
      <div className='hero__img-box hero__video-box mt-4'>
         <video src={heroVideo} alt='' controls/>
      </div>
     </Col>

      <Col lg='2'>
         <div className='hero__img-box mt-5 '>
            <img src={frontbuilding} alt=''/>
          </div>
      </Col>

      
    </Row> 
   </Container>
</section>
   {/*=========== hero section ===========*/}


   <section>
      <Container>
         <Row>
           <Col lg='3'>
               <h5 className='services__subtitle'>What we serve</h5>
               <h2 className='services__title'>we  offer our best services</h2>
           </Col>
           <ServiceList/>
           </Row>
      </Container>
   </section>


   {/* =============== featured tour section start ===================*/}
  
   <section>
     <Container>
       <Row>
         <Col lg='12' className='mb=5'>
            <Subtitle subtitle={'Explore'}/>
            <h2 className='featured__tour-title'>Our Hotel Rooms & Conference Hall</h2>
         </Col>
         <FeaturedTourList/>
       </Row>
     </Container>
   </section>

   {/* =============== featured tour section end ===================*/}
    
 

   {/*================= gallery section start ===================*/}
     
   <section>
     <Container>
        <Row>
            <Col lg='12'>
               <Subtitle subtitle={'Gallery'}/>
               <h2 className='gallery__title' id='gallery'>
               Visit our gallery</h2>
            </Col>

            <Col lg='12'>
               <MasonryImaesGallery />
            </Col>
        </Row>
     </Container>
   </section>
   {/*================= gallery section end ===================*/}

   {/*================= Testimonial section start ===================*/}
    <section>
      <Container>
         <Row>
            <Col lg='12'>
                <Subtitle subtitle={'Other Services'} />
            </Col>
            <Col lg='12 '>
               <Testimonial />
            </Col>
         </Row>
      </Container>
    </section>
   {/*================= Testimonial section end ===================*/}

   <Newsletter/>
  </>  
};

export default Home